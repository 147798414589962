<template>
  <v-container fluid>
    <CreateTypes ref="eventForm" mode="edit" />
  </v-container>
</template>

<script>
import { showTypes } from "@/services/OrdinaryJustice";
import Swal from "sweetalert2";
import CreateTypes from "./CreateTypes.vue";

export default {
  name: "editTypes",
  data() {
    return {
      newCase: {
        id: "",
        name: "'"
      },
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    this.eventId = id;
    try {
      this.showLoader();
      const eventShowResponse = await showTypes(id);
      const newCase = {
        id: eventShowResponse.data.id,
        name: eventShowResponse.data.name,
      };
      this.$nextTick(() => {
        this.$refs.eventForm.setEventData(newCase);
      });
      this.hideLoader();
    } catch (err) {
      this.hideLoader();
      console.error(err);
      Swal.fire(
        "Error",
        "ha ocurrido un error al procesar la solicitud",
        "error"
      );
    }
  },
  methods: {
    setEventData(eventData) {
      this.newCase = eventData;
    },
  },
  components: { CreateTypes },
};
</script>

<style scoped></style>
